import logo from './assets/logo.png';
import './App.css';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Only Funs - All things fun and entertaining</title>
        <meta name="description" content="Only Funs is a vibrant and engaging online platform dedicated to bringing joy and entertainment into people’s lives. " />
        <meta name="keywords" content="fun joy entertainment games videos" />
        <meta name="author" content="Ersocon" />
        <meta property="og:title" content="Only Funs - All things fun and entertaining" />
        <meta property="og:description" content="Only Funs is a vibrant and engaging online platform dedicated to bringing joy and entertainment into people’s lives." />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://www.onlyfuns.xyz" />
        <meta name="twitter:title" content="Only Funs - All things fun and entertaining" />
        <meta name="twitter:description" content="Only Funs is a vibrant and engaging online platform dedicated to bringing joy and entertainment into people’s lives." />
        <meta name="twitter:image" content={logo} />
        <meta name="twitter:card" content="summary_large_image" />
        <script defer data-domain="onlyfuns.xyz" src="https://stats.ersocon.net/js/script.hash.js"></script>
      </Helmet>

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="hero-slogan">
          Only Funs is an innovative online platform that serves as a one-stop destination for all things fun and entertaining. 
          Designed with a mission to inject more joy and laughter into daily life.
        </p>
      </header>
    </div>
  );
}

export default App;
